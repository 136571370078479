

.homediv {
    margin: 4rem 6rem;
  
}


.homediv h1 {
    font-size: 3rem;
    font-family: 'El Messiri', sans-serif;
}

.homediv p {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    color: rgb(93, 80, 80);
}

.first-des {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.des-text {
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
}

.des-text h2 {
    padding-bottom: 1rem;
}

.des-text p {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    font-size: 1rem;
    padding-bottom: 30px;
}

.image {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.image img {
    width: 48%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.image img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
}

/* Add these styles to your CSS file */

.what-we-do{
    padding-top: 130px;


}

.what-we-do h3{
    color:#FF9900;

}  

.what-we-do .cards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px; 
    gap: 10px;
}

.what-we-do .card {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 450px;
    height: 550px;
  
}

.what-we-do .card img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.what-we-do .card p {
    margin-top: 10px;
}

/* Media query for tablets and smaller screens */
@media only screen and (max-width: 768px) {
    .what-we-do {
        padding-top: 80px; /* Adjusted padding for smaller screens */
    }

    .what-we-do .cards {
        flex-direction: column; /* Stack cards vertically on smaller screens */
    }

    .what-we-do .card {
        width: 100%; /* Full width for smaller screens */
        height: auto; /* Adjusted height to allow content to determine height */
    }
}


@media screen and (max-width: 850px) {
    .homediv {
        margin: 4rem 2rem;
    }

    .first-des {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4.5rem;
    }

    .des-text {
        width: 100%;
    }

    .image {
        width: 100%;
        margin: 1.8rem 0;
    }

    .image img {
        height: 250px;
    }
}


/*services styles*/

/* special style */
/* special style */

/* Add these styles to your CSS file */

.special__categor {
    margin-top: 4rem;
    background-color: #f8f8f8; /* You can set the background color as needed */
    padding: 50px;
  }
  
  .special__container {
    margin: 0 auto;
  }
  
  .special__box {
    text-align: center;
  }
  
  .special__box h2.what-we-do {
    font-size: 2.5rem;
    font-family: 'El Messiri', sans-serif;
    color: #333; /* Set the desired text color */
  }
  
  .special__box h3.what-we-do {
    font-size: 1.5rem;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    color: #555; /* Set the desired text color */
    margin-top: 1rem;
  }
  
  /* ... (any other existing styles) */

  


.special__category {
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 3fr));
    justify-content: center;
    gap: 2.5rem;
   
  }
  
  .zoomed-in {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    border: 3px solid #808080;
    animation: borderShine 2s infinite;
  }
  
  .special__description {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1rem;
    color: #121111; /* You can adjust the color as needed */
    line-height: 1.5;
    margin-top: 10px;
  }
  @keyframes borderShine {
    0% {
      border-color: #f4f4f4;
      box-shadow: 0 0 5px #dde7dd;
    }
    50% {
      border-color: #f6da68;
      box-shadow: 0 0 15px #f0d461;
    }
    100% {
      border-color: #ffffff;
      box-shadow: 0 0 5px #ffffff;
    }
  }
  
  .special__title {
    font-size: var(--h2-font-size);
    margin-bottom: 0.25rem;
    font-weight: 700;
  }
  /* end */
  