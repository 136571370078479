/* Products.css */

.products {
    background-color: #f8f8f8;
    padding: 80px 0;
  }
  
  .products__container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section__title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .products__filters {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .products__item {
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s;

  }
  
  .products__item:hover {
    background-color: rgba(255, 160, 17, 0.515);
  }
  
  .active-product {
    background-color:rgb(255, 160, 17);
    color: #fff;
  }
  
  .products__title {
    font-size: 1.2rem;
    margin-bottom: -25px;
  }
  
  .products__stock {
    font-size: 0.6rem;
    color: #040404;
  }

  
  
  .products__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .products__card {
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 10px;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-in.active {
    transform: translateY(-10px);
  }
  
  .products__shape {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }
  
  .products__img {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .products__data {
    padding: 20px;
  }
  
  .products__name1 {
    font-size: 1.2rem;
    /* margin-bottom: 5px; */
  }
  
  .products__availability{
        color: #4caf50;
        font-size: 0.9rem;
      
      }
  
  .products__size2,
  .products__details,
  .products__details2,
  .info-message {
    font-size: 0.7rem;
    color: #555;
    margin: -5px;
  }

  .products__img {
    width: 100%;
    height: 200px; /* Set a fixed height for all images, adjust as needed */
    object-fit: cover; /* Ensure the image covers the specified height */
    display: block;
  }
  
  .products__button2,
  .button {
    display: block;
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    background-color: rgb(255, 160, 17);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .products__button2:hover,
  .button:hover {
    background-color: #08440b;
  }
  
  .info-message {
    background-color: #f44336;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    display: inline-block;
  }

  /* Larger than standard desktop */
@media only screen and (min-width: 1200px) {
    .products__container {
      max-width: 1400px;
    }
  
    .products__filters {
      margin-bottom: 40px;
    }
  
    .products__item {
      padding: 15px 25px;
    }
  
    .products__title {
      font-size: 1.4rem;
    }
  
    .products__size2,
    .products__details,
    .products__details2,
    .info-message {
      font-size: 0.8rem;
    }
  
    .products__button2,
    .button {
      padding: 8px;
      margin-top: 15px;
    }
  }
  
  /* Standard desktop */
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .products__container {
      max-width: 1100px;
    }
  
    .products__filters {
      margin-bottom: 35px;
    }
  
    .products__item {
      padding: 12px 20px;
    }
  
    .products__title {
      font-size: 1.3rem;
    }
  
    .products__size2,
    .products__details,
    .products__details2,
    .info-message {
      font-size: 0.75rem;
    }
  
    .products__button2,
    .button {
      padding: 7px;
      margin-top: 12px;
    }
  }
  
  /* Tablets */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .products__container {
      max-width: 90%;
    }
  
    .products__filters {
      margin-bottom: 30px;
    }
  
    .products__item {
      padding: 10px 15px;
    }
  
    .products__title {
      font-size: 1.2rem;
    }
  
    .products__size2,
    .products__details,
    .products__details2,
    .info-message {
      font-size: 0.7rem;
    }
  
    .products__button2,
    .button {
      padding: 6px;
      margin-top: 10px;
    }
  }
  
  /* Mobile devices */
  @media only screen and (max-width: 767px) {
    .products__container {
      max-width: 100%;
    }
  
    .products__filters {
      margin-bottom: 25px;
    }
  
    .products__item {
      padding: 8px 12px;
    }
  
    .products__title {
      font-size: 1.1rem;
    }
  
    .products__size2,
    .products__details,
    .products__details2,
    .info-message {
      font-size: 0.65rem;
    }
  
    .products__button2,
    .button {
      padding: 5px;
      margin-top: 8px;
    }
  }
  
  
  