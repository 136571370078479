.corporate-container {
    background: linear-gradient(to right, #160909, rgb(35, 24, 8));
    color: #fff;
    padding: 40px;
    text-align: center;
    animation: fadeIn 1s ease-in-out forwards, changeColor 2s ease-in-out infinite alternate;
  }
  
  .corporate-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .corporate-title {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .corporate-section {
    background-color: #444;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .corporate-text {
    font-size: 1.2em;
    line-height: 1.6;
    text-align: left;
  }
  
  .corporate-text strong {
    color: rgb(255, 160, 17);
  }
  
  .corporate-text em {
    font-style: italic;
  }
  
  .corporate-text ul {
    list-style-type: none;
    padding: 0;
  }
  
  .corporate-text li {
    margin-bottom: 10px;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .corporate-container {
      padding: 20px;
    }
  
    .corporate-title {
      font-size: 2em;
    }
  
    .corporate-text {
      font-size: 1em;
    }
  }
  