
.NavbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 80px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%) ;
    z-index: 9999;  
    border-radius: 13px;
    background-color: rgba(255, 255, 255, 0.9);
 
}
.navbar-logo {
    font-size: 1.5rem;
    padding-top: 10px;
    cursor: pointer;
    text-shadow: 2px 2px 4px rgba(255, 250, 250, 0.8);
    display: inline-block;
    animation: colorChange 4s infinite; /* 2 seconds for each color, repeat infinitely */
  }
  
  .word1 {
    animation: colorChangeWord1 4s infinite; /* 2 seconds for each color, repeat infinitely */
  }
  
  .word2 {
    animation: colorChangeWord2 4s infinite; /* 2 seconds for each color, repeat infinitely */
  }
  
  @keyframes colorChange {
    0%, 100% {
      color: #de800d; /* First color */
    }
    50% {
      color: #222; /* Second color */
    }
  }
  
  @keyframes colorChangeWord1 {
    0%, 100% {
      color: #FF9209; /* First color */
    }
    50% {
      color: #222; /* Second color */
    }
  }
  
  @keyframes colorChangeWord2 {
    0%, 100% {
      color: #222; /* Second color */
    }
    50% {
      color: #FF9209; /* First color */
    }
  }
  
  
  
  
/*Rupasinghe logo animated end */



.nav-menu{
    display: grid;
    grid-template-columns: repeat(9, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    transition: none;
    animation: none;
    
}

.nav-links{
    text-decoration: none;
    color: #222;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links i{
    padding-right:10px;
}
 
.nav-links:hover{
    background-color: #FF9209;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.nav-links-mobile{
   display: none;
}


button:hover {
    background-color: rgb(235, 9, 9);
    color: white;
    animation: none; 
}


.menu-icons{
    display: none;
}

@media screen and (max-width: 850px){

    .NavbarItems{
       z-index: 99;
       transition: none;
       animation: none;
    }
    .nav-menu{
       display: flex;
       flex-direction: column;
       justify-content:flex-start;
       background:white;
       width: 100%;
       height:auto;
       position:absolute;
       top: 20px;;
       left: -100%;
       opacity: 0;
       align-items: stretch;
       padding: 80px 0 30px 0;
       margin: 0;
       border-radius: 13px;
     
    }

    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
     
    }

    .nav-links{
        display: block; 
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }

    .nav-links:hover{
        background: #FF9900;
        transition: none;
    }

    button{
        display: none;
    }

    .nav-links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width:80%;
        background: #FF9209;
        text-decoration:none;
        color:#fff;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
       
           
    }

    .menu-icons{
        display:block;
        cursor:pointer;
    }

    .menu-icons i{
        font-size:1.2rem;
        color:#222;
      padding-right: 20px;
    }
    
}

/* Add this style to your existing social buttons styling */

.social-buttons {
    display: flex;
    justify-content: center;
    margin-top: 1px;
}

.whatsapp-button,
.facebook-button,
.instagram-button,
.linkedin-button {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0 10px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(37, 211, 102, 0.5); /* WhatsApp green */
    }
    25% {
        box-shadow: 0 0 10px rgba(24, 119, 242, 0.5); /* Facebook blue */
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 0 20px rgba(188, 42, 141, 0.7); /* Instagram purple */
    }
    75% {
        box-shadow: 0 0 10px rgba(0, 119, 181, 0.5); /* LinkedIn blue */
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(37, 211, 102, 0.5); /* WhatsApp green */
    }
}

.whatsapp-button {
    background-color: #25d366; /* WhatsApp green */
    color: white;
}

.facebook-button {
    background-color: #1877F2;/* Facebook blue */
    color: white;
}

.instagram-button {
  background: linear-gradient(45deg, #f9ce34, #ee2a7b, #6228d7);
    color: white;
}

.linkedin-button {
    background-color: #0077b5; /* LinkedIn blue */
    color: white;
}

/* ... other styles ... */









/* Updated media query for screens smaller than 1496x1372 pixels */
@media screen and (max-width: 1200px) and (max-height: 1372px) {
    .NavbarItems {
      padding: 10px; /* Adjust padding for smaller screens */
      transition: none;
      animation: none;
    }
  
    .nav-menu {
      display: none; /* Hide the menu on medium-sized screens initially */
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 20px; /* Adjust the top position to prevent overlap with the navbar */
      left: 0;
      opacity: 0;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: 1;
      border-radius: 13px;
      transition: none;
      animation: none;
     
    }
  
    .nav-menu.active {
      display: flex; /* Show the menu when active */
      opacity: 1;
      transition: none;
      animation: none;
    }
  
    .nav-links {
      width: 100%;
      font-size: 1rem;
      padding: 1rem 0;
      text-align: center;
    }
  
    .menu-icons {
      display: block; 
    
    }
  }


  