.vision-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #000;
    color: #fff;
    opacity: 0;
    animation: fadeIn 3s ease-in-out forwards, changeColor 3s ease-in-out infinite alternate;
  }
  
  .company-name {
    font-size: 2.5em;
    margin-bottom: 20px;
    transform: scale(0.8); /* Initial scale */
 
  }
  
  .vision-column,
  .mission-column {
    width: 100%;
    text-align: left;
    padding: 30px;
    transform: translateX(-50px); /* Initial position */
    animation: slideIn 4s ease-in-out infinite alternate; /* Looping animation */
  }
  
  .vision-title {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .vision-text {
    font-size: 0.8em;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  /* Media query for smaller screens */
  @media (min-width: 768px) {
    .vision-container {
      flex-direction: row;
      justify-content: space-around;
    }
  
    .vision-column,
    .mission-column {
      width: 45%;
    }
  }
  
  /* Animation Keyframes */

  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes changeColor {
    0% {
      background-color:  #212130;
    }
    100% {
      background-color: #39304A; /* Change to your desired background color */
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(-30px);
    }
    to {
      transform: translateX(0);
    }
  }
  