
.heroM{
    width: 100%;
    height: 100vh;
    position:relative;
    background-color: black;
}
  
  /* Style for the video element */
  .heroM-video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .heroM-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.heroM-text{
    position: absolute;
    top: 45%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 100%;
    

}
.heroM-text h1 {
  font-size: 3.5rem; /* Increase font size */
  font-weight: 800;
  font-family: 'Cambria', 'Cochin', 'Georgia', 'Times', 'Times New Roman', serif;
  color: #ffffff;
  text-shadow: 5px 7px 9px rgba(41, 12, 12, 0.7); /* Adjust shadow color and transparency */
  opacity: 0;
  animation: fadeInH1 2s forwards;
}

.heroM-text p {
  font-size: 1.6rem; /* Increase font size */
  color: #d4d4d4; /* Lighter color for better readability */
  padding: 0.2rem 0 2.5rem 0; /* Adjust padding for better spacing */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adjust shadow color and transparency */
  opacity: 0;
  animation: fadeInP 2s forwards 1s; /* Delay p by 1 second */
}

@keyframes fadeInH1 {
  from {
    opacity: 0;
    transform: translateY(-20px); /* Add a slight upward animation */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInP {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Add a slight upward animation */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

  
  @keyframes fadeInH1 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInP {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .heroM-text .show{
    text-decoration: none;
    background:#ffffff;
    padding:1rem 2rem;
    border-radius: 6px;
    font-size:1.1rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
    border-color: #222;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards 1.2s;
}

.heroM-text .show:hover {
    background-color:#FF9900;
    color: white;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



@media screen and (max-width: 555px) {
  .heroM-text h1 {
    font-size: 2rem; /* Adjust font size for smaller screens */
    padding: 10px 20px; /* Adjust padding for smaller screens */
  }

  .heroM-text p {
    font-size: 1rem; /* Adjust font size for smaller screens */
    padding: 0 0 2rem 0; /* Adjust padding for smaller screens */
  }

  .heroM-text .show {
    padding: 0.6em 1rem; /* Adjust padding for smaller screens */
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}
  