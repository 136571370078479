/* Count.css */

body {
    margin: 10;
    padding: 0;
  
 
  }
  
  .count-container {
    position: relative;
    width: 100%;
    height: 60vh;
    background: url('../../assets/blurbg.jpg') center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-shadow: 2px 2px 4px rgba(59, 24, 24, 0.8);
    padding-bottom: 20px;
   
    
  }

  .count-heading {
    text-align: center;
    color: white;
    padding-top: 80px;
  }
  
  .count-heading h2 {
    font-size: 36px; /* Adjust the font size as needed */
    font-weight: bold;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .count-heading h3 {
    font-size: 24px; /* Adjust the font size as needed */
    margin-top: 10px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .shining-text {
    color: white;
    font-size: 34px;
    font-family: 'El Messiri', sans-serif;
    text-shadow: 0 0 5px white; /* Set the initial shadow */
    animation: shineAnimation 1.5s infinite alternate;
    text-decoration:underline;
  }
  
  @keyframes shineAnimation {
    from {
      text-shadow: 0 0 5px white; /* Set the initial shadow */
    }
    to {
      text-shadow: 0 0 12px #000000; /* Adjust the shining shadow as needed */
    }
  }
  
  

  .count-item {
    text-align: center;
    color: white;
    transition: transform 0.5s ease-in-out;
  }
  .count-item:hover {
    transform: scale(1.2);
  }
  
  .count-item img {
    width: 150px; /* Adjust the size of the icon */
    height: 150px; /* Adjust the size of the icon */
    margin-bottom: 10px;
  }
  
 
  
  /* Specific icons for each item */
  .count-item:nth-child(2) img {
    content: url('../../assets/partners.png');
  }
  
  .count-item:nth-child(3) img {
    content: url('../../assets/global.png');
  }
  
  .count-item:nth-child(4) img {
    content: url('../../assets/projects.png');
  }
  
  .number {
    font-size: 54px;
    font-weight: 600;
    transition: transform 0.5s ease-in-out;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }

  .number:hover{
    transform: scale(1.2); /* Increase the size on hover, adjust as needed */
  }
  


  /* Media Queries */
@media screen and (max-width: 768px) {

  .count-container {
    height: auto; /* Let the height be determined by content */
    padding: 20px; /* Add padding for better spacing */
    text-align: center; /* Center align content */
    background: url('../../assets/blurbg.jpg') center/cover no-repeat;
    /* flex-direction: column; */
    justify-content: center; /* Center align items vertically */
    align-items: center; /* Center align items horizontally */

  
   
    
  }
    .count-heading h2 {
        font-size: 14px;
    }

    .count-heading h3 {
        font-size: 8px;
    }

    .shining-text {
        font-size: 9px;
    }

    .number {
        font-size: 16px;
    }

    .count-item img {
        width: 50px;
        height: 50px;
    }
}