/* STS.css */

.sts-container {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
}


.truss-system {
  background-color: #f0f0f0;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.truss-system .refp{
  font-style: italic;
  font-size:x-small;
  text-align: center;
  padding-top: 10px;
}

.section-heading-sts {
  color: #333;
  font-size: 28px;
  margin-bottom: 20px;
}

.description {
  color: #555;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.truss-components {
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  margin-left: 20px;
}

.truss-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-top: 20px;
}
