.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: black;
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff; /* Text color */
    max-width: 80%;
    text-shadow: 15px 7px 9px rgba(0, 0, 0, 0.7);
  }
  
  h1 {
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 3rem;
    margin-bottom: 20px;
    
  }
  
  p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 10px;
  
  }
  
  .contact-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px; /* Adjust font size as needed */
    background-color: #3498db; /* Adjust background color as needed */
    color: white; /* Text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition */
  }
  
  .contact-button:hover {
    background-color: #e38204; /* Adjust hover background color as needed */
    color: rgb(255, 255, 255);
  }
  /* Hero.css */

.c-button {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    background-color: #FF9209;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    animation: pulse 0.8s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(255, 146, 9, 0.5);
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 0 20px rgba(246, 135, 0, 0.7);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(255, 146, 9, 0.5);
    }
}

button:hover {
    background-color: rgb(235, 9, 9);
    color: white;
    animation:none; /* Disable the pulse animation on hover */
}


/* media qs from gpt */

/* Larger screens */
@media screen and (min-width: 768px) {
  .c-button {
    font-size: 1.2rem; /* Increase font size for larger screens */
  }
}

/* Medium-sized screens */
@media screen and (max-width: 768px) {
  h1 {
    font-size: 2.5rem; /* Adjust h1 font size for medium-sized screens */
  }

  p {
    font-size: 1rem; /* Adjust paragraph font size for medium-sized screens */
  }
}

/* Small screens */
@media screen and (max-width: 576px) {
  h1 {
    font-size: 2rem; /* Adjust h1 font size for small screens */
  }

  p {
    font-size: 0.9rem; /* Adjust paragraph font size for small screens */
  }

  .c-button {
    font-size: 1rem; /* Adjust button font size for small screens */
  }
}

 
