.scy {
  
  background-color: white; /* You can set the background color as needed */
  /* padding-top: 60px; Adjust as needed */
  /* padding-bottom: 60px; */
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}



.sg{
  cursor: pointer;
}

.sib{
  padding-top: 20px;
  background-color: white;
}


.zoomed-iN{
  border-radius:100px;
  width: 100%;
  height: 100%;
  border: 3px solid   rgb(255, 160, 17);
  /* animation: borderShine 2s infinite; */
}
.sco {
  margin: 0 auto;
  padding-top: -120px;
}

.sb {
  text-align: center;
  
}

.sc {
  /* margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 3fr));
  justify-content: center;
  gap: 2.5rem; */
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 3fr));
  justify-content: center;
  gap: 2.5rem;
 
}

.st{
  cursor: pointer; /* Add pointer cursor */
transition: color 0.3s ease; /* Add smooth transition for color change on hover */
animation: breathingAnimation 4s infinite;
}

.st:hover {
color:  rgb(255, 160, 17);
}

@keyframes breathingAnimation {
0% { transform: scale(1); }
50% { transform: scale(1.1); }
100% { transform: scale(1); }
}




@keyframes borderShine {
  0% {
    border-color: #f4f4f4;
    box-shadow: 0 0 5px #dde7dd;
  }
  50% {
    border-color: #f6da68;
    box-shadow: 0 0 15px #f0d461;
  }
  100% {
    border-color: #ffffff;
    box-shadow: 0 0 5px #ffffff;
  }
}



/* Media Queries */

@media only screen and (max-width: 768px) {
  .scy {
    padding-left: 15px;
    padding-right: 15px;
  }

  .scy h2 {
    padding-top: 20px;
    color: #f0d461;
    font-size: x-small;
  }

  .sc {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1.5rem;
  }

  .sco {
    padding-top: -80px;
  }
}

@media only screen and (max-width: 480px) {
  .scy {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sc {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
  }

  .sco {
    padding-top: -40px;
  }

  .st{
    font-size:x-small;
  }
}
